<template>
<!--    <iframe ref="iframe" :srcdoc="html" frameborder="0" height="100vh" width="100%" @load="reinitIframe()"></iframe>-->
    <div v-html="html" ref="html"></div>
</template>

<script>
  export default {
    name: "onLinePay",
    data () {
      return {
        html: ''
      }
    },
    created() {
      this.html = sessionStorage.getItem('onLinePayData');
      setTimeout(() => {
        document.getElementById('toPay').submit();
      }, 1000)
    },
    methods: {
      reinitIframe () {
        let iframe = this.$refs['iframe'];
        iframe.height = document.documentElement.clientHeight - 10 + 'px'
      }
    },
    components: {}
  }
</script>

<style>
    .rightbox p{ margin-top: 0 !important}
</style>
